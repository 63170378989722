export enum FormDataTypes {
    Salutation = 1,
    FirstName = 2,
    MiddleName = 3,
    LastName = 4,
    PreferredName = 5,
    Gender = 6,
    MaritalStatus = 7,
    DateOfBirth = 8,
    RetirementAge = 9,
    ResidencyStatus = 10,
    SalutationOther = 11,
    GenderText = 12,
    HomePhone = 13,
    WorkPhone = 14,
    MobilePhone = 15,
    Email = 16,
    Address1 = 17,
    Address2 = 18,
    PostCode = 19,
    State = 20,
    Suburb = 21,
    ReceiveCentrelinkPayment = 22,
    HasSeniorHealthCareCard = 23,
    ProvidesCaretoAnotherSick = 24,
    CentreLinkIncomeFrequency = 25,
    PrivateHealth = 26,
    PrivateHealthFund = 27,
    Employer = 28,
    Occupation = 29,
    EmploymentType = 30,
    HoursWorkedPerWeek = 31,
    SuperGuaranteePercentage = 32,
    StartOfEmployment = 33,
    AnnualLeaveDays = 34,
    SickLeaveDays = 35,
    LongServiceLeaveDays = 36,
    HasWill = 37,
    DateOfWill = 38,
    EnduringPowerOfAttorneyInPlace = 39,
    GeneralPowerOfAttorneyInPlace = 40,
    MedicalPowerOfAttorneyInPlace = 41,
    DoYouHaveATestamentaryTrust = 42,
    Name = 43,
    Age = 44,
    AgeDependentTo = 45,
    TimeFrame = 47,
    AdviceTopic = 48,
    Comment = 49,
    NotBeingInclude = 50,
    Health = 51,
    AreYouCurrentlyPregnant = 52,
    DueDateAndAnyAbnormalTestResults = 53,
    Height = 54,
    Weight = 55,
    BMI = 56,
    HaveYouGainedLostMoreThanFiveKG = 57,
    HaveYouSmokedAnySubstancesLastTwelveMonths = 58,
    HowManyStandardDrinksPerWeek = 59,
    AreYouCurrentlyOnAnyMedication = 60,
    WhatTypeDosageHowLong = 61,
    HaveYouHadAnySurgeries = 62,
    DoctorVisitResults = 63,
    ImmediateFamilySignificantMedicalCondition = 64,
    RelationshipNameMedicalCondition = 65,
    AnyMedicalCondition = 66,
    MedicalConditionDetailsDiagnosed = 67,
    ClaimForPersonalInsurance = 68,
    TakenAnyDrugsInFiveYear = 69,
    MedicationDosageRequired = 70,
    DeclinedPostponedInsurance = 71,
    ProfessionalTradeQualifications = 72,
    PercentageWorkOfficeAdminBased = 73,
    PercentageWorkRequiresManualLabour = 74,
    PercentageWorkWhichDriving = 75,
    WorkUnderground = 76,
    JobLength = 77,
    ParticipateContactCompetitiveSports = 78,
    HaveYouGainedLostMoreThanFiveKGComment = 79,
    HaveYouSmokedAnySubstancesLastTwelveMonthsComment = 80,
    ClaimForPersonalInsuranceComment = 81,
    DeclinedPostponedInsuranceComment = 82,
    ProfessionalTradeQualificationsComment = 83,
    WorkUndergroundComment = 84,
    JobLengthComment = 85,
    ParticipateContactCompetitiveSportsComment = 86,
    CentrelinkBenefitType = 87,
    HealthReferralSentAndConsentGiven = 88,
}
